/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 16:45:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-12-14 13:41:31
 */

/**
     * ruleTypes: 规则类型
     * genRules： rule 生成函数
     * ctx： Vue实例
     */
import { genModels } from '@/plugins/widget/c-form/rules';
import { eduLevel, payLevel, getType, lookStatus, advertLink } from '@/utils/options';

export var models = genModels(function (genRules, ctx) {return [
  {
    span: 18,
    kname: 'advertName',
    itemProps: {
      label: '名称：',
      rules: [genRules('require')] },

    component: {
      props: {
        maxlength: 20 } }


    /*showFn(val) {
                            //return ctx.editable
                            return val === ""
                          }*/ },

  {
    kname: 'imageUrl',
    itemProps: {
      label: '图片：',
      rules: [genRules('require')] },

    component: {
      name: 'upload-image',
      props: {
        limit: 1,
        maxSize: 1024,
        url: '/employee/honors/upload' } } }




  //  span:0,
  //   kname: 'jupId',

  // }
  ];});

// form 默认值
export var formData = function formData() {return {};};